
@import '~@/assets/sass/design.sass'

.photo
  position: relative
  &Alt
    text-align: center
  &Container
    position: relative
    padding: $base-spacing
  &Button
    margin-top: $base-spacing
  &Overlay
    display: flex
    align-items: center
    justify-content: space-around
    width: 100%
    height: 100%
    color: $c-white
    opacity: 0
    transition: opacity .3s ease-in-out
    @include center
    &::before
      z-index: -1
      width: 100%
      height: 100%
      content: ''
      background: $green-blue-purple-gradient
      border-radius: 8px
      opacity: 0.8
      @include center
  &:hover &Overlay
    opacity: 1
.library
  max-height: 85vh
  overflow: auto
  &Button
    padding: $base-spacing
.icon
  &Add
    color: $c-acc-green
  &Delete
    color: $c-acc-red
  &Edit
    color: $c-san-juan-blue
  &Hover
    cursor: pointer
